<template>
  <div class="header">
    <div class="headerup">
      <div class="headerupleft" @click="goList">
        <!-- 选择模板 -->
        <svg class="icon" aria-hidden="true" style="margin-right: 10px">
          <use href="#icon-back"></use></svg
        >{{ $t("label.back_forms") }}
      </div>
      <!-- 选择对象与模板 -->
      <span class="tit">{{ $t("c140") }}</span>
      <!-- <div class="headerupcenter" ref="headleft">
        <input v-model="headline" v-show="editLineFlg" />
        <span>{{ editLineFlg ? "" : headline }}</span>
        <svg class="img" aria-hidden="true" @click="editLine">
          <use href="#icon-editName"></use>
        </svg>
      </div> -->
      <div class="headerupright">
        <el-button @click="goCreate" size="mini" type="primary">{{
          $t("label.import.page1.nextstep")
        }}</el-button>
      </div>
    </div>
    <div class="nav" :style="{ minHeight: tableheight }">
      <select-template @selectTemp="selectTemp" @getObjectid="getObjectid" @getownerobjectid="getownerobjectid"></select-template>
    </div>
  </div>
</template>
<script >
import selectTemplate from '@/views/marketForm/createFrom/fromType/selectTemplate.vue'
export default {
  data() {
    return {
      selectTempFlg: 'nullModel', //模板flg

      editLineFlg: false,
      headline: "",
      fromFlg: true,
      followFlg: false,
      preview_: false,
      opt: false,
      actionsoptions: '',
      currentStep: 2,
      actions: "Actions",
      mydefault: " ",
      formpreview: "Learn more",
      tableheight: `${document.body.offsetHeight - 179}px`,
      options: [
        {
          value: "选项1",
          label: "Mirror",
        },
        {
          value: "选项2",
          label: "This is it",
        },
      ],
    }
  },
  components: {
    selectTemplate
  },
  created() {
    let newdate = new Date();
    let Y = newdate.getFullYear();//年
    var M = newdate.getMonth();//月
    let D = newdate.getDate();//日
    let H = newdate.getHours();//时
    let m = newdate.getMinutes() < 10 ? "0" + newdate.getMinutes() : newdate.getMinutes(); //分
    let S = newdate.getSeconds() < 10 ? "0" + newdate.getSeconds() : newdate.getSeconds();//秒
    let monlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    let Mon = monlist[M];
    let Mdate;
    if (H >= 12) {
      if ((H - 12) >= 10) {
        Mdate = Mon + '  ' + D + " , " + Y + "  " + (H - 12) + ":" + m + ":" + S + '  PM '
      } else {
        Mdate = Mon + '  ' + D + " , " + Y + "  " + '0' + (H - 12) + ":" + m + ":" + S + '  PM '
      }

    } else {
      if (H < 10) {
        Mdate = Mon + '  ' + D + " , " + Y + "  " + "" + '0' + H + ":" + m + ":" + S + ' AM '
      } else {
        // eslint-disable-next-line no-unused-vars
        Mdate = Mon + '  ' + D + " , " + Y + "  " + "" + H + ":" + m + ":" + S + ' AM '
      }

    }
    this.headline = "Newform" + this.formatDateTime()
    this.$store.state.creatform.channel = this.headline;
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
    this.$store.state.creatform.objectid = ''
    this.$store.state.creatform.ownerobjectid = ''
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  methods: {
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let headleft = this.$refs.headleft;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (headleft) {
        if (!headleft.contains(e.target)) {
          this.editLineFlg = false;
        }
      }
    },
     // 格式化时间
    formatDateTime() {
      // var y = new Date().getFullYear();
      var m = new Date().getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = new Date().getDate();
      d = d < 10 ? "0" + d : d;
      let hh =
        (new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours());
      let mm =
        (new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes());
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
        return m + d +hh + mm + ss;
    },
    //获取模板信息
    selectTemp(no) {
      this.selectTempFlg = no?no:"nullModel"
    },
    //获取对象信息
    getObjectid(objectid) {
      this.$store.state.creatform.objectid = objectid
    },
    //获取子对象信息
    getownerobjectid(ownerobjectid) {
      this.$store.state.creatform.ownerobjectid = ownerobjectid
    },
    editLine() {
      this.editLineFlg = !this.editLineFlg
    },
    // 退回列表页
    goList() {
      this.$router.push({
        path: '/marketForm/marketForm'
      })
    },
    // 进入下一步
    goCreate() {
      this.$store.state.creatform.itemDataId = '';
      this.$store.state.creatform.actionType == 'new';
      this.$store.state.creatform.selectTempFlg = this.selectTempFlg
      this.$router.push({
        path: '/marketForm/createFrom',
        query: {
          createddata: this.headline,
        }
      })
    },
    handleCommand(command) {
      this.mydefault = command;
    },
    // 选择器
    handleClick(tab) {
      if (tab.index === "3") {
        this.followFlg = false
        this.preview_ = true;
        this.fromFlg = false
        this.opt = false;
      } else if (tab.index === "2") {
        this.followFlg = false
        this.preview_ = false;
        this.fromFlg = false
        this.opt = true;
      } else if (tab.index === "1") {
        this.followFlg = true
        this.preview_ = false;
        this.fromFlg = false
        this.opt = false;
      } else if (tab.index === "0") {
        this.followFlg = false
        this.preview_ = false;
        this.fromFlg = true
        this.opt = false;
      }
    },
  }
}
</script>
<style scoped lang='scss'>
.icon {
  display: inline-block;
  cursor: pointer;
}
.header {
  width: 100%;
  height: 100%;
  background: #fff;
  // margin: 10px auto;
  .headerup {
    height: 60px;
    background: #f5f5f5;
    border: 1px solid #dddbda;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tit{
      height: 43px;
      font-size: 18px;
      color: #080707;
      line-height: 43px;
    }
    .headerupleft {
      font-family: MicrosoftYaHei;
      color: #080707;
      letter-spacing: 0;
      margin-left: 15px;
      font-size: 14px;
    }
    .headerupcenter {
      width: 60%;
      height: 60px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #080707;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 15.1px;
        height: 15.2px;
        cursor: pointer;
        margin-left: 10px;
      }
      span {
        display: inline-block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      input {
        max-width: 90%;
      }
    }
    .headerupright {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      margin-right: 15px;
      button {
        width: 65px;
        height: 30px;
        background: #2D6CFC;
        border-radius: 3px;
        outline: none;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
  .headerdown {
    height: 61px;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-top: none;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
    .headerdownright {
      button {
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
      }
    }
  }
}
.stepBox {
  margin: 10px 2px;
  background-color: #ffffff;
}
.nav {
  width: 100%;
  background: #fff;
  padding-bottom: 32px;
  box-sizing:border-box;
  display: flex;
  justify-content: center;
}
</style>