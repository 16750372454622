<!--
 * @Author: your name
 * @Date: 2022-02-16 15:37:50
 * @LastEditTime: 2022-03-22 10:35:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\marketForm\createFrom\fromType\staticTemplate.vue
-->
<template>
  <div>
    <div class="null_box" v-show="staticType === '1'">
      <!-- 空白模板 -->
      <span>{{ $t("c135") }}</span>
    </div>
    <div class="static_wrap" v-show="staticType !== '1'">
      <div class="static_box">
        <div class="logo">LOGO</div>
        <div class="formimg" v-show="staticType === '2'">
          <!-- 表单背景 -->
          <span>{{ $t("label.background.the.form") }}</span>
        </div>
        <div>
          <!-- 表单背景 -->
          <h3>{{ $t("label.form.title") }}</h3>
          <!-- 请在此处输入文本简介信息 -->
          <h5>{{ $t("c136") }}</h5>
          <ul>
            <li v-for="(val, i) in arr" :key="i">
              <span>{{ val.name }}</span>
              <p>{{ val.value }}</p>
            </li>
          </ul>
          <!-- 地图 -->
          <div class="map" v-show="staticType === '3'"><span>{{ $t("label.icon.172") }}</span></div>
          <!-- 提交 -->
          <div class="btn">{{ $t("label.forecast.customforecast.button.submit") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    staticType: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      arr: [
        {
          name: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          name: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          name: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {},
};
</script>
<style lang='scss' scoped>
.null_box {
  width: 100%;
  height: 430px;
  line-height: 430px;
  text-align: center;
  background: #fefefe;
  box-shadow: 0px 2px 12px 0px rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: bold;
  span{
    color: #979797;
  }
}
.static_wrap {
  .static_box {
    width: 100%;
    height: 430px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    border: 1px solid #979797;
    .logo {
      margin: 14px;
      width: 65px;
      height: 29px;
      font-size: 16px;
      font-weight: bold;
      color: #2d6cfc;
      background: #f3f7ff;
      line-height: 29px;
          text-align: center;
    }
    h3,
    h5 {
      text-align: center;
    }
    h3 {
      margin: 10px 8px 0;
      font-size: 14px;
      font-weight: bold;
      color: #979797;
    }
    h5 {
      margin: 0;
      font-size: 12px;
      color: #979797;
    }
    ul {
      li {
        margin-top: 5px;
        margin-left: 16px;
        span {
          display: inline-block;
          width: 24px;
          height: 12px;
          font-size: 12px;
          color: #979797;
          line-height: 16px;
        }
        p {
          width: 92%;
          height: 25px;
          background: #f3f7ff;
          border-radius: 2px;
          border: none;
        }
      }
    }
    .map,
    .formimg {
      line-height: 90px;
      margin: 16px;
      text-align: center;
      height: 90px;
      background: #f3f7ff;
      span {
        width: 96px;
        height: 31px;
        font-size: 14px;
        font-weight: bold;
        color: #979797;
        line-height: 31px;
      }
    }
    .btn {
      text-align: center;
      margin: 15px auto;
      line-height: 30px;
      width: 55px;
      height: 30px;
      background: #2d6cfc;
      border-radius: 4px;
      font-size: 12px;
      color: #ffffff;
    }
  }
}
</style>