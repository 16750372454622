!<!-- 静态开发：张聿通 -->
<template>
  <div class="top">
    <div class="headBox">
      <div class="titleBox">
        <!-- <div class="mainTitle">{{ $t("label.choose_formtype") }}</div>
        <p class="subtitle">{{ $t("label.what_create") }}</p> -->
        <!-- 选择对象 -->
        <span>{{ $t("label.selectobject") }}</span>
        <el-select v-model="objectid" filterable @change="objChange">
          <el-option
            v-for="item in formOptions"
            :key="item.id"
            :label="item.labelname"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span class="add_s" @click="addFun" v-show="!isChidren">+</span>
        <span class="line_s" v-show="isChidren"></span>
        <el-select v-model="ownerobjectid" v-show="isChidren">
          <el-option
            v-for="item in objOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span class="add_s" @click="reduceFun" v-show="isChidren" style="background:red;">-</span>
      </div>
      <!-- 选择您的表单模板}-->
      <p class="subtitle">{{ $t("c137") }}</p>
    </div>
    <div class="previewsTemp">
      <div class="pre_box_30">
        <div @click="selectTemp(-1)" class="" :style="temp_00">
          <staticTemplate :staticType="oneType"></staticTemplate>
        </div>
        <!-- 空白模板 -->
        <div class="static_name">{{ $t("c135") }}</div>
      </div>
      <div class="pre_box_30">
        <div @click="selectTemp(0)" class="" :style="temp_01">
          <staticTemplate :staticType="twoType"></staticTemplate>
        </div>
        <!-- 公司模板 -->
        <div class="static_name">{{ $t("c138") }}</div>
      </div>
      <div class="pre_box_30">
        <div @click="selectTemp(1)" :style="temp_02" class="preTempTow_box">
          <staticTemplate :staticType="threeType"></staticTemplate>
        </div>
        <!-- 会议模板 -->
        <div class="static_name">{{ $t("c139") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import staticTemplate from "./staticTemplate.vue";
import * as FormApi from "./api.js";

export default {
  components: {
    staticTemplate,
  },
  data() {
    return {
      tempNo: null,
      temp_00: "",
      temp_01: "",
      temp_02: "",
      oneType: "1",
      twoType: "2",
      threeType: "3",
      objectid: "lead",//对象id
      ownerobjectid:'',//子对象id
      formOptions:[],
      objOptions: [],
      isChidren:false,
    };
  },
  computed: {},
  watch: {
    objectid(nval){
      this.$emit('getObjectid',nval)
    },
    ownerobjectid(nval){
      this.$emit('getownerobjectid',nval)
    },
  },
  mounted(){
    this.init()
  },
  //方法集合
  methods: {
    init(){
      //获取所有对象
      FormApi.getAllObjectList().then((res)=>{
        this.formOptions = res.data
        this.$emit('getObjectid','lead')
      }).catch(()=>{
      })
    },
    objChange(e){
      this.ownerobjectid = ''
      this.isChidren = false
      this.value1 = ""
      this.objOptions = []
      FormApi.getFormDetailObject({objectid:e}).then((res)=>{
        this.objOptions = res.data
        
      }).catch(()=>{
      })
    },
    addFun(){
      this.isChidren = true
    },
    reduceFun(){
      this.isChidren = false
    },
    selectTemp(no) {
      if (no === -1) {
        this.tempNo = no;
        this.temp_00 = "border: 3px solid #409eff;border-radius:3px;";
        this.temp_01 = "";
        this.temp_02 = "";
        this.$emit("selectTemp", "nullModel");
      } else if (no === 0) {
        this.tempNo = no;
        this.temp_00 = "";
        this.temp_01 = "border: 3px solid #409eff;border-radius:3px;";
        this.temp_02 = "";
        this.$emit("selectTemp", "generalMode");
      } else {
        this.tempNo = no;
        this.temp_00 = "";
        this.temp_01 = "";
        this.temp_02 = "border: 3px solid #409eff;border-radius:3px;";
        this.$emit("selectTemp", "positioningModel");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.top {
  width: 100%;
}
.headBox {
  width: 100%;
  margin-top: 25px;
    padding: 0 15px;
    box-sizing: border-box;
  .subtitle {
    height: 29px;
    font-size: 16px;
    color: #333333;
    line-height: 29px;
    margin: 15px 0;
  }
  .titleBox {
    display: flex;
    align-items: center;
    .add_s{
      display: inline-block;
    width: 20px;
    height: 20px;
    background: #2D6CFC;
    color:#fff;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    margin-left:10px;
    cursor:pointer;
    }
    .line_s{
          display: inline-block;
    width: 60px;
    height: 1px;
    background: #979797;
    margin-left: 10px;
    }
    span {
      height: 30px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-right: 12px;
    }
    .el-select {
      width: 200px;
      height: 30px;
      ::v-deep .el-input {
        input {
          height: 30px !important;
        }
        .el-input__icon{
          line-height:30px;
        }
      }
    }
  }
  .mainTitle {
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    font-size: 18px;
    color: #080707;
    letter-spacing: 0;
  }
}
.previewsTemp {
  width: 900px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  .pre_box_30 {
    width: 240px;
    div {
      width: 100%;
      div {
        width: 100%;
        div {
          width: 100%;
        }
      }
    }
  }
  .static_name {
    font-weight: bold;
    color: #333333;
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
  }
  // .preTemp_01 {
  //   width: 350px;
  //   height: 500px;
  // }
  .preTempOne_box {
    margin-right: 30px;
  }
  .preTemTow_box {
    margin-left: 30px;
  }
  .preTemTow_box:hover {
    border: 1px solid #409eff;
  }
  .preTempOne_box:hover {
    border: 1px solid #409eff;
  }
  // .preTemp_02 {
  //   width: 350px;
  //   height: 500px;
  // }
}
</style>